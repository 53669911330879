const withTimeout = (promise, ms) => {
  return Promise.race([
    promise,
    new Promise((_, reject) => setTimeout(() => reject(new Error('Promise timeout')), ms))
  ]);
};

export const jobSearchInit = async ({ app, store, query, error, params, route }) => {
  try {
    // console.log('jobSearchInit', { app, store, query, error, params, route });
    // console.log('query', query);
    const sorting =
      query.sorting || app.$cookies.get('jobSorting') || store.getters['meta/jobSorting'].default;
    // const paramsCountry = params.country || query.countryCode || 'denmark';
    const paramsCountry = params.country;
    // const paramsCity = params.city || 'copenhavn';
    const paramsCity = params.city;
    const siteCountries = store.getters['meta/siteCountries'] || [];
    const countryCode =
      siteCountries.filter(
        (siteCountry) => siteCountry.text?.toLowerCase() === paramsCountry?.toLowerCase()
      )?.[0]?.value ||
      query.countryCode ||
      store.getters['meta/sitePreferredCountryCode'];

    // const location = paramsCity
    //   ? `${paramsCity}, ${paramsCountry}`
    //       .toLowerCase()
    //       .split(' ')
    //       .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    //       .join(' ')
    //   : query.location;

    const capitalizeWords = (str) => str.replace(/\b\w/g, (char) => char.toUpperCase());
    const location = paramsCity
      ? capitalizeWords(`${paramsCity}, ${paramsCountry}`.toLowerCase())
      : query.location;

    const searchParams = {
      includeSuggestions: true,
      ...((params.city || query.location) && { location }),
      sorting,
      ...query,
      countryCode
    };

    // await store.dispatch('jobs/getJobsAndFeatured', searchParams);

    await withTimeout(store.dispatch('jobs/getJobsAndFeatured', searchParams), 15000);

    return {
      countryCode,
      country: paramsCountry,
      ...((params.city || query.location) && { location })
      // ...(params.city && { city: paramsCity })
    };
  } catch (err) {
    if (err && err.message) {
      console.error(err.message);
    }
  }
};

const queryDic = {};

const countryDic = {
  default: {
    title: 'Tech & Startup Jobs',
    description:
      'As one of the five Nordic countries. Find high-paying technical & startup jobs on The Hub.'
  },
  denmark: {
    title: 'Tech & Startup Jobs in Denmark',
    description:
      'As one of the five Nordic countries, Denmark is a hotbed for some of the most successful startup companies in history. Find high-paying technical & startup jobs in Denmark on The Hub.'
  },
  sweden: {
    title: 'Tech & Startup Jobs in Sweden',
    description:
      'As one of the five Nordic countries, Denmark is a hotbed for some of the most successful startup companies in history. Find high-paying technical & startup jobs in Denmark on The Hub.'
  },
  norway: {
    title: 'Tech & Startup Jobs in Norway',
    description:
      'As one of the five Nordic countries, Norway is a hotbed for some of the most successful startup companies in history. Find high-paying technical & startup jobs in Norway on The Hub.'
  },
  finland: {
    title: 'Tech & Startup Jobs in Finland',
    description:
      'As one of the five Nordic countries, Finland is a hotbed for some of the most successful startup companies in history. Find high-paying technical & startup jobs in Finland on The Hub.'
  }
};

const cityDic = {
  default: {
    title: 'Tech & Startup Jobs',
    description:
      'As one of the largest Nordic cities. Find high-paying technical & startup jobs on The Hub.'
  },
  'denmark/copanhagen': {
    title: 'Tech & Startup Jobs in Copanhagen',
    description:
      'As one of the largest Nordic cities, Copanhagen is a hotbed for some of the most successful startup companies in history. Find high-paying technical & startup jobs in Denmark on The Hub.'
  },
  'denmark/aarhus': {
    title: 'Tech & Startup Jobs in Aarhus',
    description:
      'As one of the largest Nordic cities, Aarhus is a hotbed for some of the most successful startup companies in history. Find high-paying technical & startup jobs in Denmark on The Hub.'
  },
  'sweden/stockholm': {
    title: 'Tech & Startup Jobs in Stockholm',
    description:
      'As one of the largest Nordic cities, Stockholm is a hotbed for some of the most successful startup companies in history. Find high-paying technical & startup jobs in Sweden on The Hub.'
  },
  'sweden/malmö': {
    title: 'Tech & Startup Jobs in Malmö',
    description:
      'As one of the largest Nordic cities, Malmö is a hotbed for some of the most successful startup companies in history. Find high-paying technical & startup jobs in Sweden on The Hub.'
  },
  'norway/oslo': {
    title: 'Tech & Startup Jobs in Oslo',
    description:
      'As one of the largest Nordic cities, Oslo is a hotbed for some of the most successful startup companies in history. Find high-paying technical & startup jobs in Norway on The Hub.'
  },
  'norway/trondheim': {
    title: 'Tech & Startup Jobs in Trondheim',
    description:
      'As one of the largest Nordic cities, Trondheim is a hotbed for some of the most successful startup companies in history. Find high-paying technical & startup jobs in Norway on The Hub.'
  },
  'finland/helsinki': {
    title: 'Tech & Startup Jobs in Helsinki',
    description:
      'As one of the largest Nordic cities, Helsinki is a hotbed for some of the most successful startup companies in history. Find high-paying technical & startup jobs in Finland on The Hub.'
  },
  'finland/espoo': {
    title: 'Tech & Startup Jobs in Espoo',
    description:
      'As one of the largest Nordic cities, Espoo is a hotbed for some of the most successful startup companies in history. Find high-paying technical & startup jobs in Finland on The Hub.'
  }
};

const capitalizeFirstChar = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const getCurrentMonthYearString = () => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const currentDate = new Date();
  const month = months[currentDate.getMonth()];
  const year = currentDate.getFullYear();
  return `${month} ${year}`;
};

const getCountryMeta = ({ country }) => ({
  mandatoryTitle: `Tech & Startup Jobs in ${capitalizeFirstChar(
    country
  )} | The Hub, ${getCurrentMonthYearString()}`,
  description: `As one of the five Nordic countries, ${capitalizeFirstChar(
    country
  )} is a hotbed for some of the most successful startup companies in history. Find high-paying technical & startup jobs in ${capitalizeFirstChar(
    country
  )} on The Hub.`
});

const getCityMeta = ({ country, city }) => ({
  mandatoryTitle: `Tech & Startup Jobs in ${capitalizeFirstChar(
    city
  )} | The Hub, ${getCurrentMonthYearString()}`,
  description: `As one of the largest Nordic cities, ${capitalizeFirstChar(
    city
  )} is a hotbed for some of the most successful startup companies in history. Find high-paying technical & startup jobs in ${capitalizeFirstChar(
    country
  )} on The Hub.`
});

export const getMetaData = ({ query, country, city }) => {
  try {
    if (query) {
      // console.log(`getMetaData | query: ${query}`);
      return queryDic[`${query.toLowerCase()}`] || {};
    }
    if (!query && country && !city) {
      return getCountryMeta({ country });
      // return countryDic[`${country.toLowerCase()}`] || countryDic.default;
    }
    if (!query && country && city) {
      return getCityMeta({ country, city });
      // return cityDic[`${country.toLowerCase()}/${city.toLowerCase()}`] || cityDic.default;
    }
    return {};
  } catch (err) {
    console.error(err);
  }
};
